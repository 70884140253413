export default {
  "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© ", _interpolate(_named("year")), ", Débuter mes impôts. Tous droits réservés."])},
  "gb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GB"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "saveSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement réussi"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimité"])},
  "menuClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer le menu"])},
  "menuOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le menu"])},
  "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["français"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anglais"])},
  "form": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requis"])}
  },
  "table": {
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée à afficher"])},
    "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées par page"])},
    "paginationSeparator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sur "])}
  },
  "validation": {
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire"])}
  },
  "phone": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# de téléphone"])},
    "extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste"])},
    "isPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaît dans le formulaire pré impôts"])},
    "types": {
      "CellPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellulaire"])},
      "Work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travail"])},
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicile"])},
      "Fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
      "EmergencyLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne d'urgence"])},
      "TollFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans frais"])},
      "Pager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléavertisseur"])},
      "HearingImpaired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malentendant"])}
    }
  },
  "address": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
    "addressLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse, ligne 1"])},
    "addressLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse, ligne 2"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "isPublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaît dans le formulaire pré impôts"])}
  },
  "provinces": {
    "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontario"])},
    "QC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Québec"])}
  },
  "messages": {
    "markAllAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer tous les messages comme lus"])}
  },
  "faultyQuestions": {
    "topMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines questions obligatoires sont sans réponses."])},
    "deleteThisMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer ce message"])}
  },
  "preTaxFormLayout": {
    "taxYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année d'imposition :"])},
    "taxProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province d'imposition :"])},
    "leftMenu": {
      "familyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations familiales"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes informations"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conjoint"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfants"])},
      "preTaxForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire pré impôts"])},
      "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])}
    },
    "subscriptionHasExpired": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement expiré"])},
      "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'abonnement de <i>", _interpolate(_named("orgName")), "</i> n'a pas été renouvelé pour l'année en cours. Il est donc impossible d'accéder à cette page."])}
    },
    "saveAndGoToPrevious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et reculer"])},
    "saveAndLogOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et quitter"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
    "saveAndGoToNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et continuer"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
    "removePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette personne n'est plus mon conjoint ou ma conjointe / je ne désire plus compléter le formulaire de cette personne."])},
    "useSameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser la même adresse courriel pour les deux conjoints"])},
    "useSameAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser la même adresse pour les deux conjoints"])},
    "basedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon les informations fiscales disponibles en date du"])}
  },
  "preTaxFormSectionPage": {
    "mandatoryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique un champ obligatoire"])}
  },
  "preTaxFormLoginPage": {
    "returningUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder au formulaire pré impôts"])},
      "moreText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez fournir une adresse courriel pour continuer. Nous vous enverrons un code d'authentification à usage unique."])},
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez-moi un code"])}
    },
    "helpLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide? Téléchargez notre guide de démarrage rapide."])},
    "messages": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Veuillez réessayer plus tard."])}
    }
  },
  "preTaxFormIntroPage": {
    "fiscalYearSelect": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année d'imposition"])},
      "questionPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour quelle année désirez-vous remplir votre formulaire pré impôts? L'année en cours est "])},
      "questionPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", mais il est possible de remplir le formulaire pour une année précédente."])}
    },
    "provinceSelect": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province de résidence"])},
      "questionPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle était votre province de résidence au"])},
      "questionPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31 décembre"])},
      "refuseButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser et quitter"])},
      "acceptButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter et continuer"])}
    },
    "optInMessage": {
      "part1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En remplissant ce formulaire, je consens à ce que les renseignements fournis soient utilisés par mon comptable et/ou ses employés dans le cadre de la préparation de ma déclaration fiscale pour l'année ", _interpolate(_named("year")), "."])},
      "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces informations pourraient être, en tout ou en partie, transmises aux autorités fiscales si nécessaire."])},
      "part3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cadre de la production de la déclaration de revenus, elles pourraient être utilisées dans des applications tiers, tel que les logiciels de traitement des impôts et hébergées sur les serveurs de ces logiciels. Pour plus d'information concernant l'utilisation de vos données, veuillez consulter votre comptable."])}
    }
  },
  "preTaxFormUserPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations familiales"])},
    "sectionTitles": {
      "personalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
      "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de contact"])},
      "residentialAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse résidentielle"])},
      "mailingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de correspondance"])},
      "mailingAddressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(si différente de l'adresse résidentielle)"])}
    },
    "partnerPreQuestions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conjoint"])},
      "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devons-nous préparer la déclaration de votre conjoint?"])},
      "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous obtenu la permission de votre conjoint de transmettre ses renseignements personnels?"])},
      "noPermissionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous répondez non, votre conjoint devra remplir son propre formulaire en se rendant à cette adresse :"])}
    }
  },
  "preTaxFormChildrenPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations familiales - Enfants"])},
    "dependentChildren": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfants à charge"])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous des enfants à charge?"])}
    },
    "addChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un enfant"])}
  },
  "childInfoInput": {
    "newChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel enfant"])},
    "dependentOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la charge de"])},
    "bothPartners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux conjoints"])},
    "removeMeAsParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet enfant n'est plus à ma charge / je ne désire plus compléter le formulaire de cet enfant. Ceci n'affectera pas le compte de l'autre parent."])},
    "removeChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer cet enfant"])}
  },
  "preTaxFormSecureSpaceSelectPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace sécurisé Convoflo"])},
    "form": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs espace sécurisés trouvés"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs espaces sécurisés* entre votre comptable et vous ont été trouvés. Veuillez en choisir un pour continuer."])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel espace sécurisé voulez-vous utiliser?"])},
      "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez vérifier s'il s'agit du bon espace sécurisé en cliquant sur le lien suivant :"])},
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et continuer"])}
    },
    "partnerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les espaces sécurisés sont fournis par notre partenaire"])}
  },
  "preTaxFormEndPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents à fournir"])},
    "comeBack": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accéder à votre formulaire pré impôts"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez consulter votre formulaire et la liste des documents à fournir en tout temps. Conservez l'adresse ci-bas pour facilement y revenir :"])}
    },
    "yourSharedFolder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre dossier partagé"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez dès maintenant accéder à votre espace client Convoflo (anciennement TagMyDoc), un partage sécurisé entre votre comptable et vous. Il s'agit d'une façon simple et sécuritaire de transmettre vos documents."])},
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour déposer vos documents"])}
    },
    "quitText": {
      "withUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour quitter de manière sécuritaire, cliquez sur le bouton ci-dessous. Vous serez redirigé vers le site de ", _interpolate(_named("organizationName")), "."])},
      "withoutUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour quitter de manière sécuritaire, cliquez sur le bouton ci-dessous."])}
    }
  },
  "preTaxFormAsProPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire pré impôts"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes authentifié en tant que professionnel. Pour accéder au formulaire de votre organisation, vous devez d'abord vous déconnecter."])},
    "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me déconnecter et aller au formulaire pré impôts"])}
  },
  "officeLayout": {
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "leftMenu": {
      "myOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon organisation"])},
      "organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisations"])},
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
      "filledForms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
      "adminStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
      "plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans"])},
      "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres de l'équipe"])},
      "preTaxFormCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire pré impôts"])},
      "progressCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancement"])},
      "convofloConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convoflo"])},
      "mondayConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monday.com"])}
    },
    "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide? Écrivez-nous :"])},
    "supportEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soutien", "@", "debutermesimpots.ca"])}
  },
  "tmgStatus": {
    "connectedOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre organisation est correctement connectée à Convoflo via le compte suivant :"])},
    "notConnectedOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre organisation n'est pas connectée à Convoflo. Cliquez sur le logo ci-bas pour vous y connecter."])},
    "connectedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte utilisateur est correctement connecté à Convoflo via le compte suivant :"])},
    "notConnectedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte utilisateur n'est pas connecté à Convoflo. Cliquez pour corriger la situation."])},
    "form": {
      "headerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous possédez déjà un compte <i>Convoflo</i>, utilisez ce formulaire pour le lier à votre compte <i>Débuter mes impôts</i>."])},
      "emailInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse courriel Convoflo"])},
      "passwordInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe Convoflo"])},
      "cancelButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "submitButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier les comptes"])}
    }
  },
  "organization": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'organisation"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigner / changer le logo"])},
    "monday": {
      "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé d'API"])},
      "boardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["board_id"])},
      "groupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group_id"])},
      "emailColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la colonne courriel"])},
      "taxYearColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la colonne année d'imposition"])},
      "answersPdfColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la colonne PDF réponses"])},
      "docListPdfColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la colonne PDF liste de documents"])}
    }
  },
  "user": {
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
    "preferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "birthDateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aaaa/mm/jj"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "mailingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de correspondance"])},
    "status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "waitingEmailConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de confirmation du courriel"])},
      "activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte activé"])}
    },
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role(s)"])}
  },
  "genders": {
    "Female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féminin"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
  },
  "roles": {
    "SuperAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super administrateur"])},
    "OrgAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur d'organisation"])},
    "Pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnel"])},
    "TaxPayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuable"])}
  },
  "usersDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])}
  },
  "userView": {
    "accountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations du compte utilisateur"])},
    "personalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de contact"])},
    "noContactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune information de contact n'a été fournie"])},
    "partnerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations du partenaire"])},
    "noPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun partenaire à la fiche"])},
    "openPartnerView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir la fiche du partenaire"])},
    "doesBusinessWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fait affaire avec"])}
  },
  "userForm": {
    "title": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("edit"), " un utilisateur"])},
    "personalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresses"])},
    "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une adresse"])},
    "phoneNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros de téléphones"])},
    "addPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un numéro de téléphone"])},
    "dangerZone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone dangereuse"])},
      "archiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver l'utilisateur"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez écrire <b>archive</b> dans la boîte ci-dessous pour continuer."])},
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])}
    }
  },
  "organizationsDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisations"])}
  },
  "organizationForm": {
    "title": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une organisation"])},
      "editMy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon organisation"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier une organisation"])}
    },
    "dontCreateTmdAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas créer de compte Convoflo"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresses"])},
    "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une adresse"])},
    "phoneNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros de téléphone"])},
    "addPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un numéro de téléphone"])},
    "orgDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'organisation"])},
    "convofloClientsFolder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier clients Convoflo"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez utiliser n'importe quel dossier de votre arborescence Convoflo comme dossier clients. Pour choisir un dossier, ouvrez Convoflo et naviguez jusqu'au dossier désiré. Dans la barre d'adresse de votre navigateur, copiez la partie de l'adresse suivant <i>https://app.convoflo.com/vf/</i>, par exemple : <b>12abCD/1wxYz</b>."])},
      "inputHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL dossier clients Convoflo"])}
    },
    "monday": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration monday.com"])},
      "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé d'API"])},
      "emailColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la colonne courriel"])},
      "taxYearColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la colonne année d'imposition"])},
      "answersPdfColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la colonne PDF réponses"])},
      "docListPdfColumnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la colonne PDF liste de documents"])}
    },
    "preTaxForm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation du formulaire pré impôts"])},
      "primaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur pincipale"])},
      "secondaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur secondaire"])},
      "formUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du formulaire"])},
      "formUrlExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette valeur détermine l'adresse du formulaire pré impôts de votre organisation"])},
      "formUrlTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La longueur minimale est de 3"])},
      "formUrlNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresses n'est pas disponible"])},
      "returnUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de retour"])},
      "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte d'introduction"])},
      "outroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte de clôture"])}
    },
    "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
    "administratorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'autre administrateurs pourront être ajoutés une fois l'organisation créée."])}
  },
  "organizationTable": {
    "createButtonLabel": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("create"), " une organisation"])},
    "administrators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateurs"])},
    "expiresAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
    "hasTmdAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte TMD"])},
    "tmdPlan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan TMD"])},
      "api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
      "tmd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TMD"])}
    },
    "nbPros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nb pros"])},
    "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])}
  },
  "organizationView": {
    "addAdminButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un administrateur"])},
    "addProButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un professionnel"])},
    "subscription": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun abonnement actif"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'abonnement a expiré le"])},
      "expiresOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire le"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un abonnement"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'abonnement"])}
    },
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresses"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse n'a été fournie"])},
    "phoneNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros de téléphone"])},
    "noPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun numéro de téléphone n'a été fourni"])},
    "noLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun logo n'a été fourni"])},
    "administrators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateurs"])},
    "professionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels"])}
  },
  "OrganizationStaffView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres de l'équipe"])}
  },
  "OrganizationPreTaxFormView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation du formulaire pré impôts"])},
    "colorsAndUrls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleurs et adresses"])},
    "primaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur principale"])},
    "secondaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur secondaire"])},
    "preTaxFormUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du formulaire"])},
    "returnUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de retour"])},
    "introTexts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textes d'introduction"])},
    "introTextFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "introTextEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "outroTexts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textes de clôture"])},
    "outroTextFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "outroTextEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])}
  },
  "OrganizationProgressStatesView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation de l'avancement des formulaires"])},
    "progressStates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["États d'avancement"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces états d'avancement sont utilisés pour suivre la progression des formulaires pré impôts dans votre tableau de bord. Modifiez-les pour mieux les adapter à votre processus de travail."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important : la valeur originale de l'état est utilisée lorsque la case est laissée vide."])},
      "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun état d'avancement n'a été configuré. Un administrateur de votre organization peut le faire en cliquant sur le bouton de modification au bas de cette page."])},
      "originalValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur originale"])}
    }
  },
  "organizationConvofloView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégation à Convoflo"])},
    "waitingForCvfApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente du service de Convoflo..."])},
    "status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à Convoflo"])},
      "accountType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de compte :"])}
    },
    "clientsFolder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier clients"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce dossier est la racine pour tous vos clients DMI dans Convoflo. DMI se base sur celui-ci pour trouver ou créer les espaces sécurisés de vos clients, anciens et nouveaux."])},
      "notSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dossier n'est configuré. Un administrateur de votre organization peut le faire en cliquant sur le bouton de modification au bas de cette page."])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du dossier"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Convoflo"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Convoflo"])},
      "openInConvoflo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir dans Convoflo"])}
    },
    "permissions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cas de problèmes (accès refusé dans Convoflo, dossiers manquants, etc.), vous pouvez réinitialiser les permissions de votre organisation. Tous les utilisateurs (administrateurs et professionnels) dont le compte Convoflo est bien connecté au compte DMI se verront donnés les permissions nécessaires pour accéder aux dossiers de vos clients."])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération peut prendre quelques minutes. Veuillez être patient."])},
      "taxYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour quelle année d'imposition?"])},
      "startButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])}
    }
  },
  "organizationMondayConfigView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégation à monday.com"])},
    "apiKey": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé d'API"])},
      "notSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clé d'API n'est configurée. Un administrateur de votre organization peut le faire en cliquant sur le bouton de modification au bas de cette page."])}
    },
    "formProps": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétés du formulaire monday.com"])}
    }
  },
  "AddUserToOrganizationForm": {
    "title": {
      "OrgAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un administrateur"])},
      "Pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un professionnel"])}
    }
  },
  "OrganizationSubscriptionForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])}
  },
  "plan": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût"])},
    "maxFilledForms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaires"])},
    "maxStorageGb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockage"])}
  },
  "subscription": {
    "purchasedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'achat"])},
    "expiresAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
    "pricePaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix payé (avant taxes)"])}
  },
  "plansDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans"])}
  },
  "planTable": {
    "createButtonLabel": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("create"), " un plan"])}
  },
  "planForm": {
    "title": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un plan"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un plan"])}
    },
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du plan"])},
    "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les champs sont requis."])},
    "beforeTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avant taxes"])},
    "minusOneForUnlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez -1 pour illimité"])}
  },
  "planView": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du plan"])}
  },
  "filledForm": {
    "status": {
      "Incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplet"])},
      "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complet"])}
    }
  },
  "filledFormsDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "yearFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
    "statusFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "assignedToFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribué à"])},
    "progressFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancement"])}
  },
  "filledFormTable": {
    "columns": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage des documents marqués comme envoyés par le client"])},
      "assignedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribué à"])},
      "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancement"])}
    },
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer à un membre de l'équipe"])}
  },
  "progress": {
    "Assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigné"])},
    "OnHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente du client"])},
    "Ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "ToBeReviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À réviser"])},
    "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complété"])},
    "Sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])}
  },
  "filledFormView": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Formulaire pré impôts ", _interpolate(_named("taxYear")), " : "])},
    "downloadAsPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger en format PDF"])},
    "tabs": {
      "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche client"])},
      "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfants"])},
      "docChecklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des documents à fournir"])},
      "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents déposés"])}
    },
    "actions": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver ce formulaire"])},
      "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permettre au client de modifier son formulaire"])}
    },
    "formHeader": {
      "fiscalYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année d'imposition"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])}
    },
    "filledFormHeader": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
      "firstCompletedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complété le"])}
    },
    "userInfo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche client"])},
      "clientNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de client"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarification"])},
      "personalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
      "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de contact"])},
      "notes": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces notes sont visibles par tous les membres de votre équipe, mais pas par vos clients."])}
      },
      "archiveDialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver ce formulaire"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En confirmant, ce formulaire sera archivé et ne sera plus visible dans votre tableau de bord. En cas d'erreur, vous devrez contacter le soutien technique pour le réactiver."])}
      },
      "unlockDialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permettre au client de modifier son formulaire"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En confirmant, le statut du formulaire passera de <b>Complété</b> à <b>Incomplet</b> et le client pourra à nouveau modifier son formulaire pré impôts. Ceci s'applique également à son conjoint, le cas échéant."])}
      }
    },
    "files": {
      "noTmdOrgAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre organisation n'est pas connectée à Convoflo. Cette fonctionnalité n'est donc pas disponible."])},
      "noTmdUserAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte utilisateur n'est pas connecté à Convoflo. Cette fonctionnalité ne vous est donc pas accessible."])},
      "formIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce formulaire est incomplet. Un client ne peut déposer des documents que lorsque son formulaire a été complété."])},
      "noTaxFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet utilisateur n'a pas de dossier partagé Convoflo."])},
      "createTmdShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le dossier partagé"])},
      "resendShareText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l'utilisateur n'a pas reçu d'invitation pour son espace sécurisé Convoflo, vous pouvez l'envoyer à nouveau en cliquant sur le bouton ci-dessous."])},
      "resendShareButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer l'invitation Convoflo"])},
      "invitationSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation envoyée avec succès."])}
    },
    "emptySection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune réponse à afficher pour cette section"])},
    "noChildrenOnFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun enfant au dossier"])}
  },
  "filledFormDocumentLists": {
    "formIsIncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des documents à fournir sera disponible lorsque le formulaire sera complété."])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici la liste complète des documents devant vous être fournis par ce client."])},
    "extraInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tout temps, un client peut ouvrir son formulaire, se rendre à la toute fin et cocher les documents pour vous signifier qu'ils ont été envoyés."])},
    "taxPayerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois chaque document envoyé (selon les directives de votre comptable), veuillez le cocher dans la liste suivante. La sauvegarde est automatique."])}
  },
  "filledFormDocumentList": {
    "noDocumentRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document requis"])}
  },
  "filledFormFiles": {
    "clientUploadedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents du client"])},
    "organizationUploadedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration de revenus"])},
    "openFolderInConvoflo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le dossier dans Convoflo"])}
  },
  "tmdFileTable": {
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger tout"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour envoyer un fichier"])},
    "notifyTaxPayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informer le client"])},
    "notifyTaxPayerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un courriel au client pour lui signifier que de nouveaux documents sont disponibles dans Convoflo."])}
  },
  "tmdFile": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
    "mimeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
    "lastDownloadBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé par"])},
    "lastDownloadAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé le"])},
    "uploadedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposé par"])},
    "uploadedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposé le"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
  },
  "adminStatsDashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
    "intro": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaires remplis"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce tableau présente le nombre de formulaires remplis reçus par chaque organisation pour les trois dernières années d'imposition. Point de vue facturation, les formulaires de conjoints ne comptent que pour un (1). L'information est présentée de la manière suivante : complétés / total reçu."])}
    }
  },
  "error": {
    "fileTooLarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce fichier est trop gros. Il excède le maximum de 20 mo."])}
  },
  "rules": {
    "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette date est invalide. Format attendu : yyyy/mm/jj"])},
    "emailAlreadyInUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse courriel n'est pas disponible"])}
  },
  "validations": {
    "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette valeur doit être une décimale"])},
    "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette valeur doit être un entier"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette valeur est requise"])}
  },
  "notifications": {
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune notification"])},
    "type": {
      "newDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux documents disponibles"])}
    },
    "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme lue"])}
  }
}